class {
  onMount() {
    document.querySelectorAll("form").forEach((form) => {
      // no need to undo this, because we use native form submits
      form.addEventListener("submit", (e) => {
        const { classList } = document.documentElement;
        if (classList.contains("is-submitting")) {
          e.preventDefault();
        }
        classList.add("is-submitting");
      });
    });
  }
}
style.scss {
  .is-submitting {
    --bg-disabled: #{$color-neutral-20};
    --fg-disabled: #{$color-neutral-50};

    button[type="submit"] {
      background: var(--bg-disabled);
      color: var(--fg-disabled);
      cursor: not-allowed;
    }

    @media (prefers-color-scheme: dark) {
      --bg-disabled: #{$color-neutral-90};
      --fg-disabled: #{$color-neutral-60};
    }
  }
}
