<!-- use tags -->
export interface Input {
  handler: () => void;
  value: string;
}

<effect() {
  const handler = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      input.handler();
    }
  };
  document.addEventListener("keydown", handler);
  return () => {
    document.removeEventListener("keydown", handler);
  };
}/>

<effect() {
  const el = document.getElementById(input.value) as HTMLElement;
  const handler = (event: MouseEvent | FocusEvent) => {
    if (!el.contains(event.target as Node)) {
      input.handler();
    }
  };
  document.addEventListener("click", handler);
  document.addEventListener("focusin", handler);
  return () => {
    document.removeEventListener("click", handler);
    document.removeEventListener("focusin", handler);
  };
}/>
