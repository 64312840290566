<!-- use tags -->
static const START_OF_TIME = "Thu, 01 Jan 1970 00:00:00 GMT";
static const END_OF_TIME = "Tue, 19 Jan 2038 03:14:07 GMT";
<sb-top-bar-dropdown title="Switch UI">
  <@host>
    <sb-icon icon="message-dots" title="Switch UI"/>
  </@host>
  <@hostExpanded>
    <sb-icon icon="message-dots--solid" title="Switch UI"/>
  </@hostExpanded>
  <@overlay>
    <div class="ui-switcher">
      <p> Like the new experience?</p>
      <button
        class="button button--secondary"
        id="stay-with-new-ui"
        onClick() {
          document.cookie = `clarity=1; expires=${END_OF_TIME}; path=/`;
          document.cookie = `legacy=; expires=${START_OF_TIME}; path=/`;
        }
      >
        Yes! Stay with the new UI
      </button>
      <button
        class="button button--secondary"
        id="back-to-old-ui"
        onClick() {
          document.cookie = `clarity=; expires=${START_OF_TIME}; path=/`;
          document.cookie = `legacy=1; expires=${END_OF_TIME}; path=/`;
          document.location.href = "/";
        }
      >
        Not yet. Switch back to old UI
      </button>
    </div>
    <if=$global.pendo || true>
      <sb-divider compact/>
      <div class="ui-switcher">
        <p>
          Take a one-minute survey to tell us about your experience with the new UI
        </p>
        <button class="button" id="take-survey">
          Take survey
        </button>
      </div>
    </if>
  </@overlay>
</sb-top-bar-dropdown>

<style.scss>
  .ui-switcher {
    padding: $spacing-16;
    width: 17rem;

    a {
      display: block;
      margin-top: $spacing-8;
    }

    button {
      display: block;
      margin-top: $spacing-8;
      width: 100%;
    }
  }
</style>
