export interface Input extends Marko.Input<"a"> {}
<a ...input class=["a", input.class]>
  <${input.renderBody}/>
</a>
style.scss {
  .a {
    --fg: #{$color-accent-60};
    --fg-hover: #{$color-accent-70};

    color: var(--fg);
    font-weight: $font-weight-medium;
    text-decoration: none;

    &:hover {
      color: var(--fg-hover);
      text-decoration: underline;
    }

    &:focus-visible {
      outline-color: var(--fg);
    }

    @media (prefers-color-scheme: dark) {
      --fg: #{$color-accent-40};
      --fg-hover: #{$color-accent-30};
    }
  }
}
