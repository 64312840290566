<!-- use tags -->
export interface Input {
  class?: string;
  compact?: boolean;
}
<hr class=["divider", input.compact && "divider--compact", input.class]>

<style.scss>
  .divider {
    --bg: #{$color-neutral-30};

    background: var(--bg);
    border: none;
    height: 1px;
    margin: $spacing-12 0;

    &--compact {
      margin: 0;
    }

    @media (prefers-color-scheme: dark) {
      --bg: #{$color-neutral-70};
    }
  }
</style>
