<!-- use tags -->
export interface Input {
  level: "error" | "success" | "information" | "warning";
  onDismiss?: () => void;
  renderBody: Marko.Body;
}

<div class=[
  "notification",
  `notification--${input.level}`,
  input.onDismiss && `notification--dismissable`,
]>
  <div class="notification__icon">
    <sb-icon icon=input.level title=`Notification level ${input.level}`/>
  </div>
  <div>
    <${input.renderBody}/>
  </div>
  <if=input.onDismiss>
    <button
      aria-label="Dismiss"
      class="notification__dismiss"
      onClick=input.onDismiss
    >
      <sb-icon icon="x-mark"/>
    </button>
  </if>
</div>

<style.scss>
  .notification {
    @include body3;

    align-items: start;
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: $radius-s;
    display: grid;
    font-weight: $font-weight-medium;
    grid-gap: $spacing-12;
    grid-template-columns: 1.5em 1fr;
    padding: $spacing-16;

    &__icon {
      color: var(--icon);
    }

    &__dismiss {
      background: none;
      border: none;
    }

    &--dismissable {
      grid-template-columns: 1.5em 1fr 1.5em;
    }

    &--error {
      --border: #{$color-status-error-40};
      --icon: #{$color-status-error-40};
      --bg: #{$color-status-error-05};
    }

    &--information {
      --border: #{$color-status-information-40};
      --icon: #{$color-status-information-40};
      --bg: #{$color-status-information-05};
    }

    &--success {
      --border: #{$color-status-success-40};
      --icon: #{$color-status-success-40};
      --bg: #{$color-status-success-05};
    }

    &--warning {
      --border: #{$color-status-warning-40};
      --icon: #{$color-status-warning-40};
      --bg: #{$color-status-warning-05};
    }

    @media (prefers-color-scheme: dark) {
      --bg: #{$color-neutral-85};

      &--error {
        --icon: #{$color-status-error-30};
      }

      &--information {
        --icon: #{$color-status-information-30};
      }

      &--success {
        --icon: #{$color-status-success-30};
      }

      &--warning {
        --icon: #{$color-status-warning-30};
      }
    }
  }
</style>
