<span class="external">
  <sb-icon icon="arrow-up-right"/>
</span>

style.scss {
  .external {
    font-size: 0.7em;
    line-height: 1;
    vertical-align: 0.4em;
  }
}
